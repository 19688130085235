<template>
  <div>
    <ContactSection></ContactSection>
    <FindOut></FindOut>
    <section
      class="min-h-70 flex flex-col lg:flex-row justify-center items-center bg-grey-lighter py-24 px-8 md:px-16 lg:px-32"
    >
      <div class="mb-12">
        <h2 class="text-2xl"><span class="stroke-text h1">Our</span> Office</h2>
        <a
          :href="$company.map"
          target="_blank"
          rel="noopener"
          class="contact"
          ><address class="address">
            {{$company.name}}
            <br />
            {{$company.contact.street}},
            <br />
            {{$company.contact.landmark}},
            <br />
            {{$company.contact.city}}, {{$company.contact.pin}},
            <br />
            {{$company.contact.country}}
          </address></a
        >
      </div>
      <div class="sm:m-10 sm:pl-24 flex-1">
        <div
          class="map shadow-lg px-24 rounded-lg"
          style="position: relative; overflow: hidden;"
        >
          <div
            style="height: 100%; width: 100%; position: absolute; top: 0px; left: 0px; background-color: rgb(229, 227, 223);"
          >
            <div
              class="gm-style"
              style="position: absolute; z-index: 0; left: 0px; top: 0px; height: 100%; width: 100%; padding: 0px; border-width: 0px; margin: 0px;"
            >
              <div>
                <button
                  draggable="false"
                  aria-label="Keyboard shortcuts"
                  title="Keyboard shortcuts"
                  type="button"
                  style="background: none transparent; display: block; border: none; margin: 0px; padding: 0px; text-transform: none; appearance: none; position: absolute; cursor: pointer; user-select: none; z-index: 1000002; left: -100000px; top: -100000px;"
                ></button>
              </div>
              <iframe
              aria-hidden="true"
              tabindex="0"
              aria-roledescription="map"
              style="z-index: -1; position: absolute; width: 100%; height: 100%; top: 0px; left: 0px; border: none;"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14615.774574250141!2d86.9735719!3d23.6779733!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xebd82298606e02fa!2sCodeLane%20Solutions!5e0!3m2!1sen!2sin!4v1640452347758!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContactSection from "./components/ContactSection.vue";
import FindOut from "./components/FindOut.vue";

export default {
  components: {
    ContactSection,
    FindOut
  }
};
</script>

<style lang="css" scoped></style>
